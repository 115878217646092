import Router from "./routes/router";

function App() {
	return (
		<div className="App">
			<Router />
		</div>
	);
}

export default App;
